<template>
  <div class="home">
    <v-container class="pb-1 pt-5">
      <MainLogo />
      <Search />
    </v-container>
    
    
  </div>
</template>

<script>
import MainLogo from '@/components/MainLogo.vue'
import Search from '@/components/Abonents/Search.vue'

export default {
  name: 'Abonents',
  components: {
    MainLogo,
    Search
  },
  data: () => ({
    search: '',
    
  }),
  mounted (){
    //
  },
  methods: {
    
  }
}
</script>

<style scoped lang="scss">
 
</style>
